/**************************************************
 * Nombre:       Seccion_Categorias
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     12 Hr
 **************************************************/
import {Button, Grid, useMediaQuery} from "@mui/material";
import {useEffect, useState} from "react";
import TituloNotificaciones from "../../Modulo_dashboard/ComponentesSecundarios/TituloNotificaciones";
import {theme} from "../../Tema";
import {TaskAlt} from "@mui/icons-material";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_blanco.svg'
import {subirImagenFile} from "../../Servicios/Storage/subirImagenFile";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import Formulario_Textos_Nosotros from "../Formularios/Formulario_Textos_Nosotros";
import {subirVariasImagenFile} from "../../Servicios/Storage/subirVariasImagenFile";
import {actualizarDoc} from "../../Servicios/BD/actualizarDoc";


const Seccion_Textos_Nosotros = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [recargar, setRecargar] = useState(false)
    const {props, setEntidad, obtenerEntidad} = useFormulario({valoresDefecto: {}})
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({logo: logo})


    const guardarCambios = () => {
        obtenerEntidad().then((entidad) => {
            abrirCargador('Guardando cambios')
            guardarImagenes(entidad).then((dox) => {
                if (dox.res) {
                    let obj = dox.data
                    obj.id = 'nosotros'
                    console.log(obj)
                    guardarDoc('configuraciones', obj).then((des) => {
                        if (des.res) {
                            guardarVersion()
                            alert('Cambios realizados con exito')
                        }
                        cerrarCargador()
                    })
                }
            })
        })
    }

    const guardarVersion = () => {
        obtenerDoc('configuraciones', 'version').then((dox) => {
            if (dox.res) {
                let num = dox.data.numero
                num = parseInt(num) + 1
                actualizarDoc('configuraciones', 'version', {numero: num})
            }
        })
    }

    const botones = [
        <Button
            onClick={() => guardarCambios()}
            startIcon={<TaskAlt/>}>
            Guardar
        </Button>
    ]

    const guardarImagenes = (obj) => {

        let procesado = obj

        return new Promise(resolve => {
            subirImagenFile(obj.imgFondoCell, 'nosotros').then((des) => {
                procesado.imgFondoCell = des.data

                subirImagenFile(obj.imgFondo, 'nosotros').then((des) => {
                    procesado.imgFondo = des.data

                    subirImagenFile(obj.imgFondoLarge, 'nosotros').then((des) => {
                        procesado.imgFondoLarge = des.data


                        subirImagenFile(obj.imgMision, 'nosotros').then((dis) => {
                            procesado.imgMision = dis.data

                            subirImagenFile(obj.imgVision, 'nosotros').then((pes) => {
                                procesado.imgVision = pes.data

                                subirVariasImagenFile(obj.certificados, 'nosotros').then((mas) => {
                                    procesado.certificados = mas.data
                                    return resolve({res: true, data: procesado})
                                })

                            })

                        })

                    })

                })

            })
        })


    }


    useEffect(() => {


        obtenerDoc('configuraciones', 'nosotros').then((dox) => {
            if (dox.res && dox.data) {
                setEntidad(dox.data)
            }
        })


    }, [recargar]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <TituloNotificaciones titulo={'Textos Nosotros '} textoComplementario={''}
                                      textoResaltado={`Textos e imagenes`} botones={botones}/>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                <Formulario_Textos_Nosotros props={props}/>
            </Grid>


        </Grid>
    )

}
export default Seccion_Textos_Nosotros

