/**************************************************
 * Nombre:       Seccion_Categorias
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     12 Hr
 **************************************************/
import {Button, Grid, useMediaQuery} from "@mui/material";
import {useEffect, useState} from "react";
import TituloNotificaciones from "../../Modulo_dashboard/ComponentesSecundarios/TituloNotificaciones";
import {theme} from "../../Tema";
import {Edit, TaskAlt} from "@mui/icons-material";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_blanco.svg'
import {subirImagenFile} from "../../Servicios/Storage/subirImagenFile";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import Formulario_Textos_Tours from "../Formularios/Formulario_Textos_Tours";
import {actualizarDoc} from "../../Servicios/BD/actualizarDoc";


const Seccion_Textos_Tours = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [recargar, setRecargar] = useState(false)
    const {props, setEntidad, obtenerEntidad} = useFormulario({valoresDefecto: {}})
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({logo: logo})


    const guardarCambios = () => {
        obtenerEntidad().then((entidad) => {
            abrirCargador('Guardando cambios')
            guardarImagenes(entidad).then((dox) => {
                if (dox.res) {
                    let obj = dox.data
                    obj.id = 'tours'
                    console.log(obj)
                    guardarDoc('configuraciones', obj).then((des) => {
                        if (des.res) {
                            guardarVersion()
                            alert('Cambios realizados con exito')
                        }
                        cerrarCargador()
                    })
                }
            })
        })
    }

    const guardarVersion = () => {
        obtenerDoc('configuraciones', 'version').then((dox) => {
            if (dox.res) {
                let num = dox.data.numero
                num = parseInt(num) + 1
                actualizarDoc('configuraciones', 'version', {numero: num})
            }
        })
    }

    const botones = [
        <Button
            onClick={() => guardarCambios()}
            startIcon={<TaskAlt/>}>
            Guardar
        </Button>
    ]

    const guardarImagenes = (obj) => {

        let procesado = obj

        return new Promise(resolve => {
            subirImagenFile(obj.imgMar, 'Tours').then((des) => {
                procesado.imgMar = des.data
                subirImagenFile(obj.imgAire, 'Tours').then((dux) => {
                    procesado.imgAire = dux.data
                    subirImagenFile(obj.imgTierra, 'Tours').then((dox) => {
                        procesado.imgTierra = dox.data
                        subirImagenFile(obj.imgAsesorate, 'Tours').then((mes) => {
                            procesado.imgAsesorate = mes.data

                            subirImagenFile(obj.imgFondoUno, 'Tours').then((tex) => {
                                procesado.imgFondoUno = tex.data

                                subirImagenFile(obj.imgFondoDos, 'Tours').then((tex) => {
                                    procesado.imgFondoDos = tex.data

                                    subirImagenFile(obj.imgFondoTres, 'Tours').then((tex) => {
                                        procesado.imgFondoTres = tex.data

                                        subirImagenFile(obj.imgEquipo, 'Tours').then((tus) => {
                                            procesado.imgEquipo = tus.data

                                            return resolve({res: true, data: procesado})

                                        })

                                    })
                                })
                            })

                        })
                    })
                })
            })
        })


    }


    useEffect(() => {


        obtenerDoc('configuraciones', 'tours').then((dox) => {
            if (dox.res && dox.data) {
                setEntidad(dox.data)
            }
        })


    }, [recargar]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <TituloNotificaciones titulo={'Textos tours '} textoComplementario={''}
                                      textoResaltado={`Textos e imagenes`} botones={botones}/>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                <Formulario_Textos_Tours props={props}/>
            </Grid>


        </Grid>
    )

}
export default Seccion_Textos_Tours

const filtros = [
    {
        nombre: 'Nombre',
        propiedad: 'nombre',

    },
    {
        nombre: 'Mas Reciente',
        propiedad: 'fecha',
        direccion: 'desc'

    }
]

const datosCabecera = [
    {
        nombre: 'Fecha',
        direccion: 'left',
        propiedad: 'fecha',
        tipo: 'fecha'

    },
    {
        nombre: 'Nombre',
        direccion: 'right',
        propiedad: 'nombre',

    },
    {
        nombre: 'Se muestra en',
        direccion: 'right',
        propiedad: 'mostrando',

    }


]


const datosCabeceraCell = [
    {
        nombre: 'Fecha',
        direccion: 'left',
        propiedad: 'fecha',
        tipo: 'fecha'

    },
    {
        nombre: 'Nombre',
        direccion: 'right',
        propiedad: 'nombre',

    },
]

const botonesTabla = [
    {
        nombre: 'Editar',
        icono: Edit,
        id: 'editar'
    },

]



