import {Alert, Fade, Slide, Snackbar} from "@mui/material";
import {useEffect, useState} from "react";
import {getMessaging} from "firebase/messaging";
import {onMessage} from "firebase/messaging";
import {app} from "../../fire";
import {Notifications} from "@mui/icons-material";

export const useEscucharNotificacion = () => {
    const [open, setOpen] = useState(false)
    const [titulo, setTitulo] = useState('')
    const messaging = getMessaging(app);

    useEffect(() => {


        onMessage(messaging, (payload) => {
            setOpen(true)
            setTitulo(payload.notification.title)
        });

    }, []);
    const Notificador = () => {
        return (
            <Snackbar
                open={open}
                autoHideDuration={8000}
                onClose={() => setOpen(false)}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                TransitionComponent={Slide}
                //action={action}
                role={'alert'}
                disableWindowBlurListener={true}

            >
                <Alert
                    icon={<Notifications fontSize="inherit" />}
                    onClose={() => setOpen(false)}
                    severity={'success'}
                    variant={'filled'}
                    sx={{width: '100%'}}
                >
                    {titulo}
                </Alert>
            </Snackbar>
        )
    }

    return {
        Notificador
    }
}