/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {obtenerCol} from "../../Servicios/BD/obtenerCol";
import IngresoImagenCrop from "../../Modulo_formularios/Componentes/IngresoImagenCrop";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {Facebook, HomeWork, Instagram, Mail, Notes, PhoneAndroid, Title, YouTube} from "@mui/icons-material";

const Formulario_Textos_Home = ({...props}) => {
    const [nomColecciones, setNomColecciones] = useState([])


    useEffect(() => {
        obtenerCol('colecciones').then((dox) => {
            if (dox.res) {
                let data = dox.data
                let noms = data.map((it) => it.nombre)
                setNomColecciones(noms)
            }
        })
    }, []);
    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
                sx={{p: 1}}
            >

                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                    >

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Typography sx={{fontWeight: 600}}>Imagenes Toures</Typography>
                        </Grid>

                        <Grid item container lg={4} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <IngresoImagenCrop dato={'imgMar'} alto={2} ancho={5}
                                                       {...props}
                                    />
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                                    <Typography>Mar</Typography>
                                </Grid>

                            </Grid>

                        </Grid>

                        <Grid item container lg={4} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <IngresoImagenCrop dato={'imgAire'} alto={2} ancho={5}
                                                       {...props}
                                    />
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                                    <Typography>Aventura</Typography>
                                </Grid>

                            </Grid>

                        </Grid>

                        <Grid item container lg={4} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <IngresoImagenCrop dato={'imgTierra'} alto={2} ancho={5}
                                                       {...props}
                                    />
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                                    <Typography>Tierra</Typography>
                                </Grid>

                            </Grid>

                        </Grid>


                    </Grid>
                </Grid>

                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                    >

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Typography sx={{fontWeight: 600}}>Servicios</Typography>
                        </Grid>


                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <IngresoTexto Icono={Notes} nombre={'Descripcion'} dato={'descripcionServicios'}
                                          {...props} lineas={3}
                            />
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <IngresoTexto Icono={Title} nombre={'Tagnile'} dato={'taglineServicios'}
                                          {...props}
                            />
                        </Grid>


                    </Grid>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                    >

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Typography sx={{fontWeight: 600}}>Tradicion y cultura</Typography>
                        </Grid>


                        <Grid item container lg={4} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <IngresoImagenCrop dato={'imgTradicion'} alto={5} ancho={4}
                                               {...props}
                            />
                        </Grid>

                        <Grid item container lg={8} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={1}
                            >

                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <IngresoTexto Icono={Title} nombre={'Titulo'} dato={'tituloTradicion'}
                                                  {...props}
                                    />
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <IngresoTexto Icono={Notes} nombre={'Descripcion'} dato={'descripcionTradicion'}
                                                  {...props} lineas={4}
                                    />
                                </Grid>


                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={1}
                    >

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Typography sx={{fontWeight: 600}}>Contactos</Typography>
                        </Grid>


                        <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>


                            <IngresoTexto Icono={Notes} nombre={'Descripcion'} dato={'descripcionContactos'}
                                          {...props} lineas={8}
                            />

                        </Grid>

                        <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={1}
                            >


                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <IngresoTexto Icono={Mail} nombre={'Correo'} dato={'correoContactos'}
                                                  {...props}
                                    />
                                </Grid>


                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <IngresoTexto Icono={HomeWork} nombre={'Direccion'} dato={'direccionContactos'}
                                                  {...props}
                                    />
                                </Grid>


                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <IngresoTexto Icono={PhoneAndroid} nombre={'Celular'} dato={'celularUnoContactos'}
                                                  {...props}
                                    />
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <IngresoTexto Icono={PhoneAndroid} nombre={'Celular'} dato={'celularDosContactos'}
                                                  {...props}
                                    />
                                </Grid>


                            </Grid>
                        </Grid>

                        <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={1}
                            >


                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <Typography sx={{fontWeight: 600}}>Redes Sociales</Typography>
                                </Grid>


                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <IngresoTexto Icono={Facebook} nombre={'Link Facebook'} dato={'facebook'}
                                                  {...props}
                                    />
                                </Grid>


                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <IngresoTexto Icono={Instagram} nombre={'Link Instagram'} dato={'instagram'}
                                                  {...props}
                                    />
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <IngresoTexto Icono={YouTube} nombre={'Link YouTube'} dato={'youtube'}
                                                  {...props}
                                    />
                                </Grid>


                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>

            </Grid>
        </form>

    )

}
export default Formulario_Textos_Home