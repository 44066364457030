export const copiarTextoPortapapeles = (texto) => {

    try {
        navigator.clipboard.writeText(texto);
        console.log('Contenido copiado al clipboard');
        alert('Copiado a porta papeles')
    } catch (err) {
        console.error('Falló al copiar: ', err);
    }

}