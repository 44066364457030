/**************************************************
 * Nombre:       Dialogo_Producto_Empresaria
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid} from "@mui/material";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import {TaskAlt} from "@mui/icons-material";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_blanco.svg'
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import Formulario_Tour_Galeria from "../Formularios/Formulario_Tour_Galeria";
import {subirVariasImagenFile} from "../../Servicios/Storage/subirVariasImagenFile";

const Dialogo_Toures_Galeria = ({realizado, datos, cerrar}) => {
    const {props, obtenerEntidad} = useFormulario({valoresDefecto: datos})
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({
        logo: logo,
    })


    const guardarCambios = () => {


        obtenerEntidad().then((entidad) => {
            let obj = entidad
            console.log(obj)
            abrirCargador('Guardando Cambios')

            subirVariasImagenFile(entidad.imgs, 'galeria_' + entidad.id).then((dox) => {
                if (dox.res) {
                    obj.imgs = dox.data

                    guardarDoc('toures', obj).then((des) => {
                        if (des.res) {
                            cerrar()
                            cerrarCargador()
                            alert('Cambios guardados con exito')
                            realizado()
                        } else {
                            cerrarCargador()
                            alert('Error al subir datos, por favor intentelo nuevamente')
                        }
                    })

                }
            })


        })

    }


    return (

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <Formulario_Tour_Galeria props={props}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 2}}>
                <Button
                    onClick={() => guardarCambios()}
                    startIcon={<TaskAlt/>}
                >
                    Guardar Cambios
                </Button>
            </Grid>


        </Grid>

    )

}
export default Dialogo_Toures_Galeria