/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {Dashboard, PlaylistAdd} from "@mui/icons-material";
import IngresoImagenLista from "../../Modulo_formularios/Componentes/IngresoImagenLista";

const Formulario_Tour_Galeria = ({...props}) => {


    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{p: 1}}
            >


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Dashboard} nombre={'Nombre del tour'} dato={'nombre'}  {...props}
                                  editable={false}
                    />
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoImagenLista Icono={PlaylistAdd}  nombre={'Imagenes'} dato={'imgs'}  {...props}
                    />
                </Grid>


            </Grid>
        </form>

    )

}
export default Formulario_Tour_Galeria