/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {
    AccountBalance,
    AccountCircle,
    AssignmentInd,
    GppGood,
    Policy,
    PriceCheck,
    Receipt,
    Schedule
} from "@mui/icons-material";
import IngresoDinero from "../../Modulo_formularios/Componentes/IngresoDinero";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";
import {ESTADOSPAGO} from "../../Constantes";
import {useEffect, useState} from "react";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_redondo.svg'
import {consultarPagoReferencia} from "../../Modulo_Pasarela_Pagos/Funciones/consultarPagoReferencia";
import {formatoMoneda} from "../../Modulo_tablas/Utilidades/formatoMoneda";

const Formulario_Detalle_Pago = ({...props}) => {
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({logo: logo})
    const [datos, setDatos] = useState({})
    const [urlGuia, setUrlGuia] = useState(null)
    const [pedido, setPedido] = useState({})


    const consultarPago = () => {
        let ref = props.props.getValues('referencia')

        if (ref) {
            abrirCargador('Consultando pasarela de pago ePayco')
        }

        consultarPagoReferencia(ref).then((dox) => {
            if (dox.res) {
                if (dox.res && dox.data.status === 'error') {
                    alert(dox.data.description)
                    cerrarCargador()
                    return;
                }

                let obj = {
                    estado: dox.data.x_response,
                    valor: dox.data.x_amount_ok
                }
                setDatos(obj)

            } else {
                alert('no hay datos para esta referencia de pago, consulta tu dashboard de ePayco')
            }
            cerrarCargador()
        })
    }


    useEffect(() => {
        let ped = props.props.getValues()
        setPedido(ped)
        if (ped.urlGuia) {
            setUrlGuia(ped.urlGuia)
        }
    }, [])
    return (
        <form noValidate style={{width: '100%'}}>
            <Cargador/>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{p: 1}}
            >


                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>

                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-end'}}>
                    {/*pedido &&
                        <PDFDownloadLink document={<Pdf_Recibo_Dashboard pedido={pedido} logo={logo_png}/>}
                                         fileName={"Pedido " + pedido.nombre + ' ' + pedido.apellido}
                                         style={{textDecoration: "none"}}>
                            <Button
                                startIcon={<Receipt/>}
                                size={'small'}
                                sx={{pt: 1, pb: 0.8}}
                                fullWidth
                            >
                                Recibo
                            </Button>
                        </PDFDownloadLink>
                    */}
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={AccountCircle} nombre={'Nombre'} dato={'nombre'}
                                  editable={false}
                                  {...props}

                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={AssignmentInd} nombre={'Descripcion'} dato={'descripcion'}
                                  editable={false} lineas={6}
                                  {...props}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Typography sx={{color: '#00000090'}}>Datos de Pago</Typography>
                </Grid>

                <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoSelect Icono={AccountBalance} nombre={'Pago'} dato={'estado'}
                                   opciones={ESTADOSPAGO} {...props}

                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoDinero Icono={PriceCheck} nombre={'Cargo'} dato={'pagado'}
                                   opciones={ESTADOSPAGO} {...props} editable={false}

                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Schedule} nombre={'Fecha de Pago'} dato={'fechaPago'}
                                  editable={false}
                                  {...props}

                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={AccountBalance} nombre={'Banco'} dato={'nombreBanco'}
                                  editable={false}
                                  {...props}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={Receipt} nombre={'Ref Epayco'} dato={'referenciaEpayco'}
                                  {...props} editable={false}

                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={GppGood} nombre={'Num Aprobacion'} dato={'numeroAprobacion'}
                                  {...props} editable={false}

                    />
                </Grid>


                <Grid item container lg={6} sm={6} xs={8} sx={{justifyContent: 'flex-start', marginTop: 0.5}}>
                    <Button
                        onClick={() => consultarPago()}
                        startIcon={<Policy/>} color={'primary'} size={'small'} sx={{px: 2, pt: 1, pb: 0.8}}>Verificar
                        Pago</Button>
                </Grid>

                <Grid item container lg={6} sm={6} xs={4} sx={{justifyContent: 'flex-end'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-start"
                    >

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end'}}>
                            <Typography>{datos && datos.estado}</Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end'}}>
                            <Typography>{datos && formatoMoneda(datos.valor)}</Typography>
                        </Grid>


                    </Grid>
                </Grid>


            </Grid>
        </form>

    )

}
export default Formulario_Detalle_Pago