/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {AccessAlarm, AvTimer, Dashboard, Flag, Money, PlaylistAdd, PlaylistRemove, Today} from "@mui/icons-material";
import IngresoImagenCrop from "../../Modulo_formularios/Componentes/IngresoImagenCrop";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";
import IngresoDinero from "../../Modulo_formularios/Componentes/IngresoDinero";
import IngresoTextoLista from "../../Modulo_formularios/Componentes/IngresoTextoLista";

const Formulario_Tour_Incluye = ({...props}) => {


    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{p: 1}}
            >




                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Dashboard} nombre={'Nombre del tour'} dato={'nombre'}  {...props}
                        editable={false}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Typography sx={{fontWeight: 600}} >Incluye</Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTextoLista Icono={PlaylistAdd} nombre={'Incluye'} dato={'incluidos'}  {...props}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Typography sx={{fontWeight: 600}} >No Incluye</Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTextoLista Icono={PlaylistRemove} nombre={'No Incluye'} dato={'noincluidos'}  {...props}
                    />
                </Grid>




            </Grid>
        </form>

    )

}
export default Formulario_Tour_Incluye