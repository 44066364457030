/**************************************************
 * Nombre:       Dialogo_Producto
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid} from "@mui/material";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import {LoadingButton} from "@mui/lab";
import {Delete, TaskAlt} from "@mui/icons-material";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {useState} from "react";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_color.png'
import {borrarDoc} from "../../Servicios/BD/borrarDoc";
import Formulario_Detalle_Pago from "../Formularios/Formulario_Detalle_Pago";

const Dialogo_Detalle_Pago = ({cerrar, realizado, datos = {formato: '1:1'}}) => {
    const {props, obtenerEntidad} = useFormulario({valoresDefecto: datos})
    const [cargando, setCargando] = useState(false)
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({
        logo: logo,
    })


    const guardar = () => {

        obtenerEntidad().then((entidad) => {
            abrirCargador('Subiendo Datos')
            let obj = entidad

            guardarDoc('pagos', obj).then((dox) => {
                if (dox.res) {
                    realizado()
                    cerrar()
                } else {
                    alert('Ups algo inesperado paso al subir el producto, por favor intentalo mas tarde')
                }
                cerrarCargador()
            })


        })
    }





    return (

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <Formulario_Detalle_Pago props={props} id={datos.id}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', mt: 3, mb: 1}}>
                <LoadingButton
                    loading={cargando}
                    onClick={() => guardar()}
                    startIcon={<TaskAlt/>}
                    color={'primary'}
                    variant={'contained'}
                >
                    {'Actualizar Estado'}
                </LoadingButton>
            </Grid>




        </Grid>

    )

}
export default Dialogo_Detalle_Pago