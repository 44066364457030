export function verificarPermiso() {
    // Comprobamos si el navegador soporta las notificaciones
    if (!("Notification" in window)) {
        return false
    }

    // Comprobamos si los permisos han sido concedidos anteriormente
    else if (Notification.permission === "granted") {
        // Si es correcto, lanzamos una notificación
        return true
    }

    // Si no, pedimos permiso para la notificación
    else if (Notification.permission !== "denied") {
        Notification.requestPermission().then(function (permission) {
            // Si el usuario nos lo concede, creamos la notificación
            if (permission === "granted") {
                return true
            } else {
                alert('Las notificaciones estan desactivadas')
            }
        });
    }

    // Por último, si el usuario ha denegado el permiso,
    // y quiere ser respetuoso, no hay necesidad de molestarlo.
}