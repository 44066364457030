/**************************************************
 * Nombre:       Seccion_Categorias
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     12 Hr
 **************************************************/
import {Button, Grid, useMediaQuery} from "@mui/material";
import {useObtenerColleccionTabla} from "../../Servicios/BD/useObtenerColleccionTabla";
import {useEffect, useState} from "react";
import {useDialogo} from "../../Modulo_dialogos/Hooks/useDialogo";
import TituloNotificaciones from "../../Modulo_dashboard/ComponentesSecundarios/TituloNotificaciones";
import TablaBasica from "../../Modulo_tablas/Componentes/TablaBasica";
import {theme} from "../../Tema";
import {AddPhotoAlternate, Houseboat, ManageSearch, PlaylistAdd} from "@mui/icons-material";
import {contarColeccion} from "../../Servicios/BD/Contadores/contarColeccion";
import Dialogo_Toures_Detalle from "../Dialogos/Dialogo_Toures_Detalle";
import Dialogo_Toures_Incluye from "../Dialogos/Dialogo_Toures_Incluye";
import Dialogo_Toures_Galeria from "../Dialogos/Dialogo_Toures_Galeria";

const Seccion_Tours = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [numColecciones, setNumColecciones] = useState(0)
    const [recargar, setRecargar] = useState(false)
    const {props, cargarNuevamente} = useObtenerColleccionTabla({
        coleccion: 'toures',
        filtroInicial: 'fecha',
        dirInicial: 'desc'
    })
    const {Dialogo, abrir} = useDialogo({
        Componente: Dialogo_Toures_Detalle,
        realizado: () => setRecargar(!recargar),
        titulo: 'Detalle de tour',
        grande: false,
    })

    const {Dialogo: DialogoIncluye, abrir: abrirIncluye} = useDialogo({
        Componente: Dialogo_Toures_Incluye,
        realizado: () => setRecargar(!recargar),
        titulo: 'Incluye y No Incluye tour',
        grande: false,
    })

    const {Dialogo: DialogoGaleria, abrir: abrirGaleria} = useDialogo({
        Componente: Dialogo_Toures_Galeria,
        realizado: () => setRecargar(!recargar),
        titulo: 'Galeria tour',
        grande: false,
    })

    const manejardorClicks = (row, id) => {

        switch (id) {
            case 'ver' :
                abrir(row)
                break
            case 'incluye' :
                abrirIncluye(row)
                break
            case 'galeria' :
                abrirGaleria(row)
                break

        }

    }

    const botones = [
        <Button
            onClick={() => abrir()}
            startIcon={<Houseboat/>}>Nuevo</Button>
    ]


    useEffect(() => {


        contarColeccion({coleccion: 'toures'}).then((dox) => {
            if (dox.res) {
                setNumColecciones(dox.data)
            }
        })

        cargarNuevamente()

    }, [recargar]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Dialogo/>

            <DialogoIncluye/>

            <DialogoGaleria/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <TituloNotificaciones titulo={'Toures'} textoComplementario={'en lista'}
                                      textoResaltado={`${numColecciones} Toures`} botones={botones}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 5}}>
                <TablaBasica
                    filtros={filtros}
                    datosCabecera={sCell ? datosCabeceraCell : datosCabecera}
                    {...props}
                    click={manejardorClicks}
                    botonesTabla={botonesTabla}
                    numeroFilas={numColecciones}
                />
            </Grid>


        </Grid>
    )

}
export default Seccion_Tours

const filtros = [
    {
        nombre: 'Nombre',
        propiedad: 'nombre',

    },
    {
        nombre: 'Mas Reciente',
        propiedad: 'fecha',
        direccion: 'desc'

    }
]

const datosCabecera = [
    {
        nombre: 'Fecha',
        direccion: 'left',
        propiedad: 'fecha',
        tipo: 'fecha'

    },
    {
        nombre: 'Nombre',
        direccion: 'left',
        propiedad: 'nombre',

    },
    {
        nombre: 'Subtitulo',
        direccion: 'right',
        propiedad: 'subtitulo',

    }


]


const datosCabeceraCell = [
    {
        nombre: 'Fecha',
        direccion: 'left',
        propiedad: 'fecha',
        tipo: 'fecha'

    },
    {
        nombre: 'Nombre',
        direccion: 'right',
        propiedad: 'nombre',

    }
]

const botonesTabla = [
    {
        nombre: 'Ver',
        icono: ManageSearch,
        id: 'ver'
    },
    {
        nombre: 'Incluye',
        icono: PlaylistAdd,
        id: 'incluye'
    },
    {
        nombre: 'Galeria',
        icono: AddPhotoAlternate,
        id: 'galeria'
    },

]



