/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {AccessAlarm, AvTimer, Dashboard, Flag, Money, Today} from "@mui/icons-material";
import IngresoImagenCrop from "../../Modulo_formularios/Componentes/IngresoImagenCrop";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";
import IngresoDinero from "../../Modulo_formularios/Componentes/IngresoDinero";

const Formulario_Tour_Detalle = ({...props}) => {


    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{p: 1}}
            >


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoImagenCrop ancho={1} alto={1} dato={'img'}  {...props} />
                </Grid>


                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'center'}}>
                    <IngresoSelect opciones={['Mar', 'Tierra', 'Aventura']} Icono={Dashboard} nombre={'Categoria'}
                                   dato={'categoria'}   {...props}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Dashboard} nombre={'Nombre del tour'} dato={'nombre'}  {...props}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto nombre={'Descripcion'} dato={'descripcion'}  {...props}
                                  lineas={7}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Today} nombre={'Frecuencia'} dato={'frecuencia'}  {...props}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={AvTimer} nombre={'Duracion'} dato={'duracion'}  {...props}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Flag} nombre={'Punto de encuentro'} dato={'encuentro'}  {...props}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={AccessAlarm} nombre={'Hora de Inicio'} dato={'hora'}  {...props}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'center'}}>
                    <IngresoDinero Icono={Money} nombre={'Valor UDS'} dato={'valor'}  {...props}
                    />
                </Grid>


            </Grid>
        </form>

    )

}
export default Formulario_Tour_Detalle