import axios from "axios";

export const consultarPagoReferencia = (ref) => {

    let url = `https://secure.epayco.co/validation/v1/reference/${ref}`

    return new Promise(resolve => {

        axios.get(url).then((response) => {

            return resolve({res: true, data: response.data.data})

        }).catch((err) => {
            return resolve({res: false, data: err.message})
        })

    })


}