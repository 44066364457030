/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {AccountCircle, Language, Star} from "@mui/icons-material";
import IngresoImagenCrop from "../../Modulo_formularios/Componentes/IngresoImagenCrop";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";
import IngresoRating from "../../Modulo_formularios/Componentes/IngresoRating";

const Formulario_Resena = ({...props}) => {


    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{p: 1}}
            >




                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={AccountCircle} nombre={'Nombre'} dato={'nombre'}  {...props}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto nombre={'Reseña'} dato={'descripcion'}  {...props}
                                  lineas={7}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'center'}}>
                    <IngresoRating Icono={Star} dato={'calificacion'} nombre={'Estrellas'}
                                   {...props}
                    />
                </Grid>



            </Grid>
        </form>

    )

}
export default Formulario_Resena